<template>
  <div class="hk-about" v-html="pageHtml">
    <!-- <h1>{{ $t('about.delivery.title') }}</h1>
    <div class="about-con">
      <h2>{{ $t('about.delivery.eg1.title') }}</h2>
      <p>
        {{ $t('about.delivery.eg1.content1') }}
        <br />
        {{ $t('about.delivery.eg1.content2') }}
        <br />
        {{ $t('about.delivery.eg1.content3') }}
        <br />
        {{ $t('about.delivery.eg1.content4') }}
        <br />
        {{ $t('about.delivery.eg1.content5') }}
        <br />
        {{ $t('about.delivery.eg1.content6') }}
        <br />
        {{ $t('about.delivery.eg1.content7') }}
        <br />
        {{ $t('about.delivery.eg1.content8') }}
        <br />
        {{ $t('about.delivery.eg1.content9') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.delivery.eg2.title') }}</h2>
      <p>
        {{ $t('about.delivery.eg2.content1') }}
        <br />
        {{ $t('about.delivery.eg2.content2') }}
        <br />
        {{ $t('about.delivery.eg2.content3') }}
        <br />
        {{ $t('about.delivery.eg2.content4') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.delivery.eg3.title') }}</h2>
      <p>
        {{ $t('about.delivery.eg3.content1') }}
        <br />
        {{ $t('about.delivery.eg3.content2') }}
        <br />
        {{ $t('about.delivery.eg3.content3') }}
      </p>
      <div class="cost-list">
        <ul>
          <li>{{ $t('about.delivery.eg3.content4.title1') }}</li>
          <li>{{ $t('about.delivery.eg3.content4.type1') }}</li>
          <li>{{ $t('about.delivery.eg3.content4.type2') }}</li>
          <li>{{ $t('about.delivery.eg3.content4.type3') }}</li>
        </ul>
        <ul>
          <li>{{ $t('about.delivery.eg3.content4.title2') }}</li>
          <li>{{ $t('about.delivery.eg3.content4.price1') }}</li>
          <li>{{ $t('about.delivery.eg3.content4.price2') }}</li>
          <li>{{ $t('about.delivery.eg3.content4.price3') }}</li>
        </ul>
        <ul>
          <li>{{ $t('about.delivery.eg3.content4.title3') }}</li>
          <li class="remarks">{{ $t('about.delivery.eg3.content4.remarks') }}</li>
        </ul>
      </div>
      <br />
      {{ $t('about.delivery.eg3.content5') }}
      <br />
      {{ $t('about.delivery.eg3.content6') }}
    </div>
    <div class="about-con">
      <h2>{{ $t('about.delivery.eg4.title') }}</h2>
      <p>
        {{ $t('about.delivery.eg4.content1') }}
        <br />
        {{ $t('about.delivery.eg4.content2') }}
        <br />
        {{ $t('about.delivery.eg4.content3') }}
        <br />
        {{ $t('about.delivery.eg4.content4') }}
        <br />
        {{ $t('about.delivery.eg4.content5') }}
        <br />
        {{ $t('about.delivery.eg4.content6') }}
      </p>
    </div>
    <h1 class="mt80">{{ $t('about.delivery.title2') }}</h1>
    <div class="about-con">
      <h2>{{ $t('about.delivery.eg5.title') }}</h2>
      <p>
        {{ $t('about.delivery.eg5.content1') }}
        <br />
        {{ $t('about.delivery.eg5.content2') }}
        <br />
        {{ $t('about.delivery.eg5.content3') }}
        <br />
        {{ $t('about.delivery.eg5.content4') }}
        <br />
        {{ $t('about.delivery.eg5.content5') }}
        <br />
        {{ $t('about.delivery.eg5.content6') }}
        <br />
        {{ $t('about.delivery.eg5.content7') }}
        <br />
        {{ $t('about.delivery.eg5.content8') }}
        <br />
        {{ $t('about.delivery.eg5.content9') }}
        <br />
        {{ $t('about.delivery.eg5.content10') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.delivery.eg6.title') }}</h2>
      <p>
        {{ $t('about.delivery.eg6.content1') }}
        <br />
        {{ $t('about.delivery.eg6.content2') }}
        <br />
        {{ $t('about.delivery.eg6.content3') }}
      </p>
    </div>
    <h1 class="mt80 pb0">{{ $t('about.delivery.title3') }}</h1>
    <div class="about-con">
      <p>{{ $t('about.delivery.eg7.content1') }}</p>
      <div class="cost-list">
        <ul>
          <li>{{ $t('about.delivery.eg7.content2.title1') }}</li>
          <li class="type2">{{ $t('about.delivery.eg7.content2.type1') }}</li>
          <li class="type2">{{ $t('about.delivery.eg7.content2.type2') }}</li>
        </ul>
        <ul>
          <li>{{ $t('about.delivery.eg7.content2.title2') }}</li>
          <li>{{ $t('about.delivery.eg7.content2.time1') }}</li>
          <li>{{ $t('about.delivery.eg7.content2.time2') }}</li>
        </ul>
        <ul>
          <li>{{ $t('about.delivery.eg7.content2.title3') }}</li>
          <li>{{ $t('about.delivery.eg7.content2.price1') }}</li>
          <li>{{ $t('about.delivery.eg7.content2.price2') }}</li>
          <li class="type2">{{ $t('about.delivery.eg7.content2.price3') }}</li>
        </ul>
      </div>
      <p>{{ $t('about.delivery.eg7.content3') }}</p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'HkAboutDelivery',
  data() {
    return {
      pageHtml: ''
    }
  },

  created() {
    this.loadPageHtml()
  },

  methods: {
    loadPageHtml() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/config/loadPageHtml?page=6&lang=' + self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }

      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.pageHtml = response.data.data.content
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
