<template>
  <div class="hk-about" v-html="pageHtml">
    <!-- <h1>{{ $t('about.privacy.title') }}</h1>
    <p>{{ $t('about.privacy.tips') }}</p>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg1.title') }}</h2>
      <p>{{ $t('about.privacy.eg1.content1') }}</p>
      <p>
        {{ $t('about.privacy.eg1.content2') }}
        <a href="http://www.hksts.com.hk">www.hksts.com.hk</a>
        {{ $t('about.privacy.eg1.content3') }}
      </p>
      <p>{{ $t('about.privacy.eg1.content4') }}</p>
      <p>{{ $t('about.privacy.eg1.content5') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg2.title') }}</h2>
      <p>
        {{ $t('about.privacy.eg2.content1') }}<br>
        {{ $t('about.privacy.eg2.content2') }}<br>
        {{ $t('about.privacy.eg2.content3') }}
      </p>
      <p>{{ $t('about.privacy.eg2.content4') }}</p>
      <p>{{ $t('about.privacy.eg2.content5') }}</p>
      <p>{{ $t('about.privacy.eg2.content6') }}</p>
      <p>
        {{ $t('about.privacy.eg2.content7') }}<br>
        {{ $t('about.privacy.eg2.content8') }}<br>
        {{ $t('about.privacy.eg2.content9') }}<br>
        {{ $t('about.privacy.eg2.content10') }}<br>
        {{ $t('about.privacy.eg2.content11') }}<br>
        {{ $t('about.privacy.eg2.content12') }}<br>
        {{ $t('about.privacy.eg2.content13') }}<br>
        {{ $t('about.privacy.eg2.content14') }}<br>
        {{ $t('about.privacy.eg2.content15') }}
      </p>
      <p>{{ $t('about.privacy.eg2.content16') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg3.title') }}</h2>
      <p>
        {{ $t('about.privacy.eg3.content1') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg4.title') }}</h2>
      <p>{{ $t('about.privacy.eg4.content1') }}</p>
      <p>
        {{ $t('about.privacy.eg4.content2') }}<br>
        {{ $t('about.privacy.eg4.content3') }}<br>
        {{ $t('about.privacy.eg4.content4') }}<br>
        {{ $t('about.privacy.eg4.content5') }}<br>
        {{ $t('about.privacy.eg4.content6') }}
      </p>
      <p>{{ $t('about.privacy.eg4.content7') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg5.title') }}</h2>
      <p>
        {{ $t('about.privacy.eg5.content1') }}<br>
        {{ $t('about.privacy.eg5.content2') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg6.title') }}</h2>
      <p>{{ $t('about.privacy.eg6.content1') }}</p>
      <p>
        {{ $t('about.privacy.eg6.content2') }}<br>
        {{ $t('about.privacy.eg6.content3') }}<br>
        {{ $t('about.privacy.eg6.content4') }}
      </p>
      <p>{{ $t('about.privacy.eg6.content5') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg7.title') }}</h2>
      <p>
        {{ $t('about.privacy.eg7.content') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg8.title') }}</h2>
      <p>
        {{ $t('about.privacy.eg8.content1') }}<br>
        {{ $t('about.privacy.eg8.content2') }}<br>
        {{ $t('about.privacy.eg8.content3') }}<a href="mailto:enquiry@hksts.com.hk">enquiry@hksts.com.hk</a><br>
        {{ $t('about.privacy.eg8.content4') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg9.title') }}</h2>
      <p>{{ $t('about.privacy.eg9.content1') }}</p>
      <p>{{ $t('about.privacy.eg9.content2') }}</p>
      <p>
        {{ $t('about.privacy.eg9.content3') }}<br>
        {{ $t('about.privacy.eg9.content4') }}<br>
        {{ $t('about.privacy.eg9.content5') }}<br>
        {{ $t('about.privacy.eg9.content6') }}
      </p>
      <p>{{ $t('about.privacy.eg9.content7') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.privacy.eg10.title') }}</h2>
      <p>
        {{ $t('about.privacy.eg10.content') }}<a href="mailto:enquiry@hksts.com.hk">enquiry@hksts.com.hk</a>
      </p>
    </div> -->

  </div>
</template>

<script>
export default {
  name: 'HkAboutPrivacy',
  data() {
    return {
      pageHtml: ''
    }
  },

  created() {
    this.loadPageHtml()
  },

  methods: {
    loadPageHtml() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/config/loadPageHtml?page=5&lang=' + self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }

      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.pageHtml = response.data.data.content
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
