<template>
  <div class="hk-about" v-html="pageHtml">
    <!-- <h1>{{ $t('about.company.title') }}</h1>
    <div class="about-con">{{ $t('about.company.eg1.content1') }}</div>
    <div class="about-con">{{ $t('about.company.eg1.content2') }}</div>
    <div class="about-con">{{ $t('about.company.eg1.content3') }}</div> -->
    <!-- {{ pageHtml }} -->
  </div>
</template>

<script>
export default {
  name: 'HkAboutCompany',

  data() {
    return {
      pageHtml: ''
    }
  },

  created() {
    this.loadPageHtml()
  },

  methods: {
    loadPageHtml() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/config/loadPageHtml?page=1&lang=' + self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }

      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.pageHtml = response.data.data.content
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
