<template>
  <div class="hk-about" v-html="pageHtml">
    <!-- <h1>{{ $t('about.payment.title') }}</h1>
    <h2 class="font-bold">{{ $t('about.payment.tips') }}</h2>
    <div class="about-con">
      <h2>{{ $t('about.payment.eg1.title') }}</h2>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.payment.eg2.title') }}</h2>
      <p>
        {{ $t('about.payment.eg2.content1') }}
        <br />
        {{ $t('about.payment.eg2.content2') }}
        <br />
        {{ $t('about.payment.eg2.content3') }}
      </p>
      <p>{{ $t('about.payment.eg2.content4') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.payment.eg3.title') }}</h2>
      <p>
        {{ $t('about.payment.eg3.content1') }}
        <br />
        {{ $t('about.payment.eg3.content2') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.payment.eg4.title') }}</h2>
      <p>
        {{ $t('about.payment.eg4.content1') }}
        <br />
        {{ $t('about.payment.eg4.content2') }}
        <br />
        {{ $t('about.payment.eg4.content3') }}
      </p>
      <p>
        {{ $t('about.payment.eg4.content4') }}
        <span
          class="font-bold"
        >{{ $t('about.payment.eg4.content5') }}</span>
        <br />
        {{ $t('about.payment.eg4.content6') }}
        <span
          class="font-bold"
        >{{ $t('about.payment.eg4.content7') }}</span>
        <br />
        {{ $t('about.payment.eg4.content8') }}
        <span
          class="font-bold"
        >{{ $t('about.payment.eg4.content9') }}</span>
      </p>
    </div>
    <div class="about-con">
      <p>
        {{ $t('about.payment.eg4.content22') }}
        <br />
        {{ $t('about.payment.eg4.content23') }}
        <br />
        {{ $t('about.payment.eg4.content24') }}
        <br />
        {{ $t('about.payment.eg4.content25') }}
        <br />
        {{ $t('about.payment.eg4.content26') }}
        <br />
        {{ $t('about.payment.eg4.content27') }}
        <br />
        {{ $t('about.payment.eg4.content28') }}
        <br />
        {{ $t('about.payment.eg4.content29') }}
        <br />
        {{ $t('about.payment.eg4.content30') }}
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'HkAboutPayment',
  data() {
    return {
      pageHtml: ''
    }
  },

  created() {
    this.loadPageHtml()
  },

  methods: {
    loadPageHtml() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/config/loadPageHtml?page=8&lang=' + self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }

      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.pageHtml = response.data.data.content
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
