<template>
  <div class="hk-about" v-html="pageHtml">
    <!-- <h1>{{ $t('about.conditions.title') }}</h1>
    <p>
      {{ $t('about.conditions.tips1.content1') }}
      <a href="http://www.hksts.com.hk">www.hksts.com.hk</a>
      {{ $t('about.conditions.tips1.content2') }}<br>
      {{ $t('about.conditions.tips1.content3') }}
    </p>
    <p class="mt10">{{ $t('about.conditions.tips2') }}</p>
    <p class="mt10">{{ $t('about.conditions.tips3') }}</p>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg1.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg1.content1') }}<br>
        {{ $t('about.conditions.eg1.content2') }}<br>
        {{ $t('about.conditions.eg1.content3') }}<br>
        {{ $t('about.conditions.eg1.content4') }}<br>
        {{ $t('about.conditions.eg1.content5') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg2.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg2.content1') }}<br>
        {{ $t('about.conditions.eg2.content2') }}<br>
        {{ $t('about.conditions.eg2.content3') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg3.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg3.content1') }}<br>
        {{ $t('about.conditions.eg3.content2') }}<br>
        {{ $t('about.conditions.eg3.content3') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg4.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg4.content1') }}<br>
        {{ $t('about.conditions.eg4.content2') }}<br>
        {{ $t('about.conditions.eg4.content3') }}<br>
        {{ $t('about.conditions.eg4.content4') }}<br>
        {{ $t('about.conditions.eg4.content5') }}<br>
        {{ $t('about.conditions.eg4.content6') }}<br>
        {{ $t('about.conditions.eg4.content7') }}<br>
        {{ $t('about.conditions.eg4.content8') }}<br>
        {{ $t('about.conditions.eg4.content9') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg5.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg5.content1') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg6.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg6.content1') }}<br>
        {{ $t('about.conditions.eg6.content2') }}<br>
        {{ $t('about.conditions.eg6.content3') }}<br>
        {{ $t('about.conditions.eg6.content4') }}<br>
        {{ $t('about.conditions.eg6.content5') }}<br>
        {{ $t('about.conditions.eg6.content6') }}<br>
        {{ $t('about.conditions.eg6.content7') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg7.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg7.content1') }}<br>
        {{ $t('about.conditions.eg7.content2') }}<br>
        {{ $t('about.conditions.eg7.content3') }}<br>
        {{ $t('about.conditions.eg7.content4') }}<br>
        {{ $t('about.conditions.eg7.content5') }}<br>
        {{ $t('about.conditions.eg7.content6') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg8.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg8.content1') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg9.title') }}</h2>
      <p>{{ $t('about.conditions.eg9.content1') }}</p>
      <p>{{ $t('about.conditions.eg9.content2') }}</p>
      <p>
        {{ $t('about.conditions.eg9.content3') }}<br>
        {{ $t('about.conditions.eg9.content4') }}<br>
        {{ $t('about.conditions.eg9.content5') }}
      </p>
      <p>
        {{ $t('about.conditions.eg9.content6') }}<br>
        {{ $t('about.conditions.eg9.content7') }}<br>
        {{ $t('about.conditions.eg9.content8') }}<br>
        {{ $t('about.conditions.eg9.content9') }}<br>
        {{ $t('about.conditions.eg9.content10') }}<br>
        {{ $t('about.conditions.eg9.content11') }}
      </p>
      <p>
        {{ $t('about.conditions.eg9.content12') }}<br>
        {{ $t('about.conditions.eg9.content13') }}<br>
        {{ $t('about.conditions.eg9.content14') }}<br>
        {{ $t('about.conditions.eg9.content15') }}
      </p>
      <p>
        {{ $t('about.conditions.eg9.content16') }}
        <a @click="deliverySchedule">{{ $t('about.conditions.eg9.content17') }}</a>
        {{ $t('about.conditions.eg9.content18') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg10.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg10.content1') }}
        <a @click="handleJump">{{ $t('about.conditions.eg10.content2') }}</a>
        {{ $t('about.conditions.eg10.content3') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg11.title') }}</h2>
      <p>{{ $t('about.conditions.eg11.content1') }}</p>
      <p>{{ $t('about.conditions.eg11.content2') }}</p>
      <p>
        {{ $t('about.conditions.eg11.content3') }}
        <a @click="deliverySchedule">{{ $t('about.conditions.eg11.content4') }}</a>
        {{ $t('about.conditions.eg11.content5') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg12.title') }}</h2>
      <p>{{ $t('about.conditions.eg12.content1') }}</p>
      <p>
        {{ $t('about.conditions.eg12.content2') }}
        <a @click="deliverySchedule">{{ $t('about.conditions.eg12.content3') }}</a>
        {{ $t('about.conditions.eg12.content4') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg13.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg13.content1') }}
        <a @click="exchangePolicy">{{ $t('about.conditions.eg13.content2') }}</a>
        {{ $t('about.conditions.eg13.content3') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg14.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg14.content1') }}<br>
        {{ $t('about.conditions.eg14.content2') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg15.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg15.content1') }}<br>
        {{ $t('about.conditions.eg15.content2') }}<br>
        {{ $t('about.conditions.eg15.content3') }}<br>
        {{ $t('about.conditions.eg15.content4') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg16.title') }}</h2>
      <p>{{ $t('about.conditions.eg16.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg17.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg17.content1') }}
        <a @click="privacyPolicy">{{ $t('about.conditions.eg17.content2') }}</a>
        {{ $t('about.conditions.eg17.content3') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg18.title') }}</h2>
      <p>{{ $t('about.conditions.eg18.content') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg19.title') }}</h2>
      <p>{{ $t('about.conditions.eg19.content') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg20.title') }}</h2>
      <p>{{ $t('about.conditions.eg20.content') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg21.title') }}</h2>
      <p>{{ $t('about.conditions.eg21.content') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg22.title') }}</h2>
      <p>{{ $t('about.conditions.eg22.content') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.conditions.eg23.title') }}</h2>
      <p>
        {{ $t('about.conditions.eg23.content1') }}
        <a href="mailto:enquiry@hksts.com.hk">enquiry@hksts.com.hk</a>
        {{ $t('about.conditions.eg23.content2') }}
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'HkAboutConditions',
  // methods: {
  //   handleJump () {
  //     this.$router.push({
  //       name: 'aboutPayment'
  //     })
  //   },
  //   deliverySchedule () {
  //     this.$router.push({
  //       name: 'aboutDelivery'
  //     })
  //   },
  //   exchangePolicy () {
  //     this.$router.push({
  //       name: 'aboutExchange'
  //     })
  //   },
  //   privacyPolicy () {
  //     this.$router.push({
  //       name: 'aboutPrivacy'
  //     })
  //   }
  // }
  data() {
    return {
      pageHtml: ''
    }
  },

  created() {
    this.loadPageHtml()
  },

  methods: {
    loadPageHtml() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/config/loadPageHtml?page=4&lang=' + self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }

      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.pageHtml = response.data.data.content
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
