<template>
  <div class="hk-about" v-html="pageHtml">
    <!-- <h1>{{ $t('about.faqs.title') }}</h1>
    <p class="subTitle">{{ $t('about.faqs.tips') }}</p>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg1.title') }}</h2>
      <p>{{ $t('about.faqs.eg1.content1') }}</p>
      <p>{{ $t('about.faqs.eg1.content2') }}<a href="mailto:enquiry@hksts.com.hk">enquiry@hksts.com.hk</a></p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg2.title') }}</h2>
      <p>{{ $t('about.faqs.eg2.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg3.title') }}</h2>
      <p>{{ $t('about.faqs.eg3.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg4.title') }}</h2>
      <p>
        {{ $t('about.faqs.eg4.content1') }}<br>
        {{ $t('about.faqs.eg4.content2') }}<br>
        {{ $t('about.faqs.eg4.content3') }}<br>
        {{ $t('about.faqs.eg4.content4') }}<br>
        {{ $t('about.faqs.eg4.content5') }}
        <a @click="handleJump">{{ $t('about.faqs.eg4.content6') }}</a>
        {{ $t('about.faqs.eg4.content7') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg5.title') }}</h2>
      <p>
        {{ $t('about.faqs.eg5.content1') }}<br>
        {{ $t('about.faqs.eg5.content2') }}<br>
        {{ $t('about.faqs.eg5.content3') }}<br>
        {{ $t('about.faqs.eg5.content4') }}<br>
        {{ $t('about.faqs.eg5.content5') }}<br>
        {{ $t('about.faqs.eg5.content6') }}
        <a @click="handleJump">{{ $t('about.faqs.eg5.content7') }}</a>
        {{ $t('about.faqs.eg5.content8') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg6.title') }}</h2>
      <p>{{ $t('about.faqs.eg6.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg7.title') }}</h2>
      <p>{{ $t('about.faqs.eg7.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg8.title') }}</h2>
      <p>{{ $t('about.faqs.eg8.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg9.title') }}</h2>
      <p>
        {{ $t('about.faqs.eg9.content1') }}<br>
        {{ $t('about.faqs.eg9.content2') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg10.title') }}</h2>
      <p>
        {{ $t('about.faqs.eg10.content1') }}<br>
        {{ $t('about.faqs.eg10.content2') }}<br>
        {{ $t('about.faqs.eg10.content3') }}<br>
        {{ $t('about.faqs.eg10.content4') }}
        <a @click="deliverySchedule">{{ $t('about.faqs.eg10.content5') }}</a>
        {{ $t('about.faqs.eg10.content6') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg11.title') }}</h2>
      <p>
        {{ $t('about.faqs.eg11.content1') }}
        <a @click="deliverySchedule">{{ $t('about.faqs.eg11.content2') }}</a>
        {{ $t('about.faqs.eg11.content3') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg12.title') }}</h2>
      <p>{{ $t('about.faqs.eg12.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg13.title') }}</h2>
      <p>{{ $t('about.faqs.eg13.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg14.title') }}</h2>
      <p>{{ $t('about.faqs.eg14.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg15.title') }}</h2>
      <p class="subTitle">{{ $t('about.faqs.eg15.content1') }}</p>
      <p>
        {{ $t('about.faqs.eg15.content2') }}<br>
        {{ $t('about.faqs.eg15.content3') }}<br>
        {{ $t('about.faqs.eg15.content4') }}<br>
        {{ $t('about.faqs.eg15.content5') }}<br>
        {{ $t('about.faqs.eg15.content6') }}<br>
        {{ $t('about.faqs.eg15.content7') }}
      </p>
      <p class="subTitle">{{ $t('about.faqs.eg15.content8') }}</p>
      <p>
        {{ $t('about.faqs.eg15.content9') }}<br>
        {{ $t('about.faqs.eg15.content10') }}<br>
        {{ $t('about.faqs.eg15.content11') }}<br>
        {{ $t('about.faqs.eg15.content12') }}<br>
        {{ $t('about.faqs.eg15.content13') }}<br>
        {{ $t('about.faqs.eg15.content14') }}
      </p>
      <p class="subTitle">{{ $t('about.faqs.eg15.content15') }}</p>
      <p>
        {{ $t('about.faqs.eg15.content16') }}<br>
        {{ $t('about.faqs.eg15.content17') }}
        <a @click="deliverySchedule">{{ $t('about.faqs.eg15.content18') }}</a>
        {{ $t('about.faqs.eg15.content19') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg16.title') }}</h2>
      <p>
        {{ $t('about.faqs.eg16.content1') }} <a href="mailto:enquiry@hksts.com.hk">enquiry@hksts.com.hk</a>
        {{ $t('about.faqs.eg16.content2') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg17.title') }}</h2>
      <p>{{ $t('about.faqs.eg17.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg18.title') }}</h2>
      <p>{{ $t('about.faqs.eg18.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg19.title') }}</h2>
      <p>{{ $t('about.faqs.eg19.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg20.title') }}</h2>
      <p>{{ $t('about.faqs.eg20.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg21.title') }}</h2>
      <p>{{ $t('about.faqs.eg21.content1') }}</p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg22.title') }}</h2>
      <p>
        {{ $t('about.faqs.eg22.content1') }}<a @click="privacyPolicy">{{ $t('about.faqs.eg22.content2') }}</a>{{ $t('about.faqs.eg22.content3') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.faqs.eg23.title') }}</h2>
      <p>
        {{ $t('about.faqs.eg23.content1') }}<br>
        {{ $t('about.faqs.eg23.content2') }}<br>
        {{ $t('about.faqs.eg23.content3') }}
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'HkAboutFaqs',
  // methods: {
  //   handleJump () {
  //     this.$router.push({
  //       name: 'aboutPayment'
  //     })
  //   },
  //   deliverySchedule () {
  //     this.$router.push({
  //       name: 'aboutDelivery'
  //     })
  //   },
  //   privacyPolicy () {
  //     this.$router.push({
  //       name: 'aboutPrivacy'
  //     })
  //   }
  // }
  data() {
    return {
      pageHtml: ''
    }
  },

  created() {
    this.loadPageHtml()
  },

  methods: {
    loadPageHtml() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/config/loadPageHtml?page=3&lang=' + self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }

      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.pageHtml = response.data.data.content
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
