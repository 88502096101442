<template>
  <div class="hk-about" v-html="pageHtml">
    <!-- <h1>{{ $t('about.exchange.title') }}</h1>
    <div class="about-con">
      <h2>{{ $t('about.exchange.eg1.title') }}</h2>
      <p>
        {{ $t('about.exchange.eg1.content1') }}<br>
        {{ $t('about.exchange.eg1.content2') }}<br>
        {{ $t('about.exchange.eg1.content3') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.exchange.eg2.title') }}</h2>
      <p>
        {{ $t('about.exchange.eg2.content1') }}<br>
        {{ $t('about.exchange.eg2.content2') }}<br>
        {{ $t('about.exchange.eg2.content3') }}<br>
        {{ $t('about.exchange.eg2.content4') }}<br>
        {{ $t('about.exchange.eg2.content5') }}<br>
        {{ $t('about.exchange.eg2.content6') }}<br>
        {{ $t('about.exchange.eg2.content7') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.exchange.eg3.title') }}</h2>
      <p>
        {{ $t('about.exchange.eg3.content1') }}<br>
        {{ $t('about.exchange.eg3.content2') }}<a href="mailto:enquiry@hksts.com.hk">enquiry@hksts.com.hk</a>{{ $t('about.exchange.eg3.content3') }}<br>
        {{ $t('about.exchange.eg3.content4') }}<br>
        {{ $t('about.exchange.eg3.content5') }}<br>
        {{ $t('about.exchange.eg3.content6') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.exchange.eg4.title') }}</h2>
      <p>
        {{ $t('about.exchange.eg4.content1') }}<br>
        {{ $t('about.exchange.eg4.content2') }}<a href="mailto:enquiry@hksts.com.hk">enquiry@hksts.com.hk</a>{{ $t('about.exchange.eg4.content3') }}<br>
        {{ $t('about.exchange.eg4.content4') }}<br>
        {{ $t('about.exchange.eg4.content5') }}<br>
        {{ $t('about.exchange.eg4.content6') }}<br>
        {{ $t('about.exchange.eg4.content7') }}
      </p>
    </div>
    <div class="about-con">
      <h2>{{ $t('about.exchange.eg5.title') }}</h2>
      <p>
        {{ $t('about.exchange.eg5.content1') }}<br>
        {{ $t('about.exchange.eg5.content2') }}<a @click="deliverySchedule">{{ $t('about.exchange.eg5.content3') }}</a>{{ $t('about.exchange.eg5.content4') }}<br>
        {{ $t('about.exchange.eg5.content5') }}
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'HkAboutExchange',
  // methods: {
  //   deliverySchedule () {
  //     this.$router.push({
  //       name: 'aboutDelivery'
  //     })
  //   }
  // }
  data() {
    return {
      pageHtml: ''
    }
  },

  created() {
    this.loadPageHtml()
  },

  methods: {
    loadPageHtml() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/config/loadPageHtml?page=7&lang=' + self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }

      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.pageHtml = response.data.data.content
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
