<template>
  <div class="hk-about" v-html="pageHtml">
    <!-- <h1>{{ $t('footer.contact') }}</h1>
    <div class="about-con">{{ $t('about.contact.1') }}</div>
    <div class="about-con">{{ $t('about.contact.2') }}: <a href="mailto:enquiry@hksts.com.hk">enquiry@hksts.com.hk</a></div> -->
  </div>
</template>

<script>
export default {
  name: 'HkAboutContact',

  data() {
    return {
      pageHtml: ''
    }
  },

  created() {
    this.loadPageHtml()
  },

  methods: {
    loadPageHtml() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/config/loadPageHtml?page=2&lang=' + self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }

      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.pageHtml = response.data.data.content
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .hk-about {
    table {
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;

      td {
        border-top: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }

      th {
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
      }
    }
  }
</style>