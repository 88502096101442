<template>
  <div class="hk-content">
    <div class="hk-menu">
      <el-menu :default-active="active" @select="handleSelect" v-show="hasSubMenu">
        <el-menu-item index="aboutCompany">
          <span slot="title">{{ $t('footer.company') }}</span>
        </el-menu-item>
        <el-menu-item index="aboutContact">
          <span slot="title">{{ $t('footer.contact') }}</span>
        </el-menu-item>
        <el-menu-item index="aboutFaqs">
          <span slot="title">{{ $t('footer.faqs') }}</span>
        </el-menu-item>
        <el-menu-item index="aboutConditions">
          <span slot="title" v-html="$t('footer.conditions2')" />
        </el-menu-item>
        <el-menu-item index="aboutPrivacy">
          <span slot="title">{{ $t('footer.privacy') }}</span>
        </el-menu-item>
        <el-menu-item index="aboutDelivery">
          <span slot="title" v-html="$t('footer.delivery2')" />
        </el-menu-item>
        <el-menu-item index="aboutExchange">
          <span slot="title">{{ $t('footer.exchange') }}</span>
        </el-menu-item>
        <el-menu-item index="aboutPayment">
          <span slot="title" v-html="$t('footer.payment2')" />
        </el-menu-item>
      </el-menu>
    </div>
    <div class="hk-about__wrapper">
      <component :is="current" />
    </div>
  </div>
</template>

<script>
import HkAboutCompany from './company'
import HkAboutContact from './contact'
import HkAboutFaqs from './faqs'
import HkAboutConditions from './conditions'
import HkAboutPrivacy from './privacy'
import HkAboutDelivery from './delivery'
import HkAboutExchange from './exchange'
import HkAboutPayment from './payment'
export default {
  name: 'HkAbout',
  data () {
    return {
      pages: {
        aboutCompany: HkAboutCompany,
        aboutContact: HkAboutContact,
        aboutFaqs: HkAboutFaqs,
        aboutConditions: HkAboutConditions,
        aboutPrivacy: HkAboutPrivacy,
        aboutDelivery: HkAboutDelivery,
        aboutExchange: HkAboutExchange,
        aboutPayment: HkAboutPayment
      }
    }
  },
  computed: {
    active () {
      return this.$route.name
    },
    current () {
      return this.pages[this.$route.name]
    },
    hasSubMenu () {
      return this.$route.meta.submenu !== false
    }
  },
  methods: {
    handleSelect (name) {
      this.$route.name !== name && this.$router.push({ name })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/index';
</style>
